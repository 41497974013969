var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main" },
    [
      _c("van-search", {
        staticClass: "van-main-search",
        attrs: {
          value: _vm.searchForm.collectionStatus
            ? _vm.utils.statusFormat(
                _vm.searchForm.collectionStatus,
                "CollectionStatus"
              )
            : null,
          readonly: "",
          clickable: "",
          name: "picker",
          "left-icon": "",
          "show-action": "",
          placeholder: "可选择付款状态查询"
        },
        on: {
          click: function($event) {
            _vm.showPicker = true
          }
        },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function() {
              return [
                _c("div", { on: { click: _vm.onSearch } }, [_vm._v(" 搜索 ")])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showPicker,
            callback: function($$v) {
              _vm.showPicker = $$v
            },
            expression: "showPicker"
          }
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              columns: _vm.constants.CollectionStatus,
              "value-key": "label"
            },
            on: {
              confirm: _vm.onConfirm,
              cancel: function($event) {
                _vm.showPicker = false
              }
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "van-refresh-list" },
        [
          _c(
            "van-pull-refresh",
            {
              on: { refresh: _vm.onRefresh },
              model: {
                value: _vm.refreshing,
                callback: function($$v) {
                  _vm.refreshing = $$v
                },
                expression: "refreshing"
              }
            },
            [
              _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": _vm.finishedText
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                _vm._l(_vm.list, function(item) {
                  return _c(
                    "van-cell-group",
                    { key: item.id, staticClass: "van-hairline-bottom" },
                    [
                      _c("van-cell", [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                item.collectionPlanNumber
                              )
                            )
                          )
                        ]),
                        _c("p", { staticClass: "lf" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.utils.statusFormat(
                                  item.collectionStatus,
                                  "paymentTypeList"
                                )
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c("van-cell", {
                        attrs: {
                          title: "待付总额（元）：",
                          value: _vm.utils.moneyFormat(item.collectionAmount)
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "已付金额（元）：",
                          value: _vm.utils.moneyFormat(item.totalAmountReceived)
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "待付金额（元）：",
                          value: _vm.utils.moneyFormat(item.amountToBeReceived)
                        }
                      }),
                      _c(
                        "van-cell",
                        [
                          _c(
                            "van-button",
                            {
                              staticClass: "lf",
                              attrs: {
                                color: "#1373CC",
                                plain: "",
                                round: "",
                                size: "small"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({
                                    name: "paymentDetail",
                                    query: {
                                      item: item,
                                      orderId: item.orderId,
                                      paymentId: item.collectionId,
                                      collectionId: item.collectionId,
                                      statementType: item.statementType,
                                      collectionAmount: item.collectionAmount,
                                      totalAmountReceived:
                                        item.totalAmountReceived,
                                      amountToBeReceived:
                                        item.amountToBeReceived
                                    }
                                  })
                                }
                              }
                            },
                            [_vm._v(" 查看详情 ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "van-row",
        { staticClass: "fixed-btn-bottom", attrs: { gutter: "20" } },
        [
          _c("van-button", {
            attrs: { type: "info", color: "#1373CC", block: "", text: "返回" },
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }