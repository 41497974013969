<template>
  <div class="van-main">
    <van-search :value="searchForm.collectionStatus?utils.statusFormat(searchForm.collectionStatus,'CollectionStatus'):null" readonly clickable name="picker"
                class="van-main-search" left-icon="" show-action placeholder="可选择付款状态查询" @click="showPicker = true"
    >
      <template #action>
        <div @click="onSearch">
          搜索
        </div>
      </template>
    </van-search>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="constants.CollectionStatus"
        value-key="label"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <div class="van-refresh-list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishedText"
          @load="onLoad"
        >
          <van-cell-group v-for="item in list" :key="item.id" class="van-hairline-bottom">
            <van-cell>
              <span>{{ utils.isEffectiveCommon(item.collectionPlanNumber) }}</span>
              <p class="lf">
                {{ utils.statusFormat(item.collectionStatus, 'paymentTypeList') }}
              </p>
            </van-cell>
            <van-cell title="待付总额（元）：" :value="utils.moneyFormat(item.collectionAmount)" />
            <van-cell title="已付金额（元）：" :value="utils.moneyFormat(item.totalAmountReceived)" />
            <van-cell title="待付金额（元）：" :value="utils.moneyFormat(item.amountToBeReceived)" />
            <van-cell>
              <van-button class="lf" color="#1373CC" plain round size="small" @click="$router.push({ name: 'paymentDetail', query: { item: item, orderId: item.orderId,paymentId: item.collectionId,collectionId: item.collectionId,statementType: item.statementType,collectionAmount:item.collectionAmount,totalAmountReceived:item.totalAmountReceived,amountToBeReceived:item.amountToBeReceived} })">
                查看详情
              </van-button>
            </van-cell>
          </van-cell-group>
        </van-list>
      </van-pull-refresh>
    </div>
    <van-row class="fixed-btn-bottom" gutter="20">
      <van-button type="info" color="#1373CC" block text="返回" @click="$router.back()" />
    </van-row>
  </div>
</template>

<script>
import { List, PullRefresh, Cell, CellGroup, Search, Button, Col, Row, Picker, Popup } from 'vant'
export default {
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [Col.name]: Col,
    [Row.name]: Row,
    [Picker.name]: Picker,
    [Popup.name]: Popup
  },
  data () {
    return {
      searchForm: {
        pi: '',
        collectionStatus: '',
        payerEnterpriseId: this.$store.state.employeeInfo.enterpriseId
      },
      finishedText: '没有更多了',
      showPicker: false,
      page: 1,
      tableList: {
        list: []
      },
      loading: false,
      finished: false,
      refreshing: false,
      list: []
    }
  },
  created () {
  },
  methods: {
    onSearch () {
      this.page = 1
      this.getList()
    },
    getList () {
      this.searchForm.pageNum = this.page
      this.api.collection.listCollectionAll(this.searchForm).then(result => {
        this.tableList = result.data.value
        if (this.tableList.pages === 0 && this._.isEmpty(this.tableList.list)) {
          this.list = []
          this.finishedText = '暂无数据'
          this.finished = true
        } else {
          if (this.tableList.pageNum === 1) {
            this.list = result.data.value.list
          } else {
            this.list = this.list.concat(result.data.value.list)
          }
          this.loading = false
          if (this.tableList.pageNum < this.tableList.pages) {
            this.searchForm.pageNum += 1
          } else {
            this.finished = true
          }
        }
      }).catch(() => {
        this.finished = true
        this.refreshing = false
        this.finishedText = '暂无数据'
      }).finally(() => {
        this.refreshing = false
      })
    },
    onLoad () {
      this.getList()
      // this.searchForm.pageNum = this.page
      // this.api.collection.listCollectionAll(this.utils.filterEmptyValue(this.searchForm)).then(result => {
      //   const resData = this._.cloneDeep(result.data.value)
      //   this.page = resData.pageNum + 1
      //   if (this.refreshing) {
      //     this.tableList = []
      //     this.refreshing = false
      //   }
      //   if (resData.pages === 0 && this._.isEmpty(resData.list)) {
      //     this.finishedText = '暂无数据'
      //     this.finished = true
      //   } else {
      //     this.loading = false
      //     this.tableList = this._.concat(this.tableList, resData.list)
      //     if (resData.pageNum === resData.pages) {
      //       this.finished = true
      //       this.finishedText = '没有更多了'
      //     }
      //   }
      // }).catch(() => {
      //   this.finished = true
      //   this.refreshing = false
      //   this.finishedText = '暂无数据'
      // })
    },
    onRefresh () {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.page = 1
      this.onLoad()
    },
    onConfirm (value) {
      this.searchForm.collectionStatus = value.value
      this.showPicker = false
    }
  }
}
</script>
  <style lang="less" scoped>
    .bt-left{
      float: left !important;
      margin-right: 10%;
      color: #F59A23
    }
    .lf{
    float: right;
    color: #F46112
    }
  </style>
